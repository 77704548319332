const ItemList = [
    "jwt-decode",
    "base64-encode",
    "base64-decode",
    "url-encode",
    "url-decode",
    "md5-hash-generator",
    "sha1-hash-generator",
    "sha256-hash-generator",
    "sha512-hash-generator",
    "uuid-generator",
    "guid-generator",
    "utf8-encode",
    "utf8-decode",
    "string-replace",
    "string-reverse",
    "string-trim",
    "string-to-lowercase",
    "string-to-uppercase",
    "string-to-camelcase",
    "string-to-kebabcase",
    "string-to-snakecase",
    "string-to-titlecase",
    "string-to-hex",
    "string-to-csv",
    "csv-to-string",
    "hex-to-string",
    "string-to-binary",
    "binary-to-string",
    "string-to-ascii",
    "ascii-to-string",
    "string-to-octal",
    "octal-to-string",
    "string-to-base64",
    "captialize-each-word",
    "base64-to-string",
    "regex-tester",
    "regex-match",
    "color-picker",
    "image-to-base64-converter",
    "base64-to-image-converter",
    "string-diff",
    "generate-random-string",
    "generate-random-number",
    "generate-random-password",
    "generate-random-hex",
    "generate-random-ip-address",
    "generate-random-mac-address",
    "remove-line-breaks",
    "alphabetical-order",
    "sentence-counter",
    "word-counter",
    "character-counter",
    "remove-extra-whitespaces",
    "remove-duplicate-lines",
    "add-line-breaks",
];

module.exports = { ItemList };