
import { Row, Col, Button } from 'antd';
import { useTranslation } from 'react-i18next';
import { baseURL } from '../consts/const';
import './footer.css';

export const Footer = (props: {}) => {
  const { t, i18n } = useTranslation();

  return (
    <footer className='footer'>
      <div className='container'>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={12} xxl={12} >
            <div style={
              { display: "flex", flexDirection: "column", alignItems: "flex-start" }
              }>
              <div className='logo-and-name'>
                <img src={`${baseURL}/logo192.png`} alt="logo" className='logo-img' />
              </div>
            </div>

          </Col>
          <Col xs={12} sm={15} md={10} lg={10} xl={4} xxl={4} >
            <div className='category-title'>{t('footer.about')}</div>
            <div className='category-item'>
              <a href={`${baseURL}/faq`}>
                {t('footer.FAQ')}
              </a>
            </div>
            <div className='category-item'>
              <a href={`${baseURL}/tos`}>{t('footer.TOS')}</a>
            </div>
          </Col>
          <Col xs={12} sm={9} md={10} lg={10} xl={5} xxl={5} >
            <div className='category-title'>{t('footer.service')}</div>
            <div className='category-item'>
            </div>
          </Col>
          <Col xs={12} sm={12} md={4} lg={4} xl={3} xxl={3} >
            <div className='category-title'>{t('footer.help')}</div>
            <div className='category-item'>
              <a href="mailto:support@allcodingtools.com" rel="noopener noreferrer">{t('contactus')}</a>
            </div>
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} md={24} lg={24} xl={24} xxl={24} >
            <div className='line-separator container'></div>
          </Col>
          <Col>
            <div className='copyright'>@allcodingtools 2024</div>
          </Col>
        </Row>
      </div>
    </footer>
  )
}